.PortfolioItems {
    background-color: #fffdf4;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 100px;
}
.container {
    background-color: #fffdf4;
}