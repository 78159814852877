.Modal {
  position: fixed;
  z-index: 200;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  background-color: white;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  top: 10%;
  left: 25%;
  width: 50%;
  transition: all 0.3s ease-out;
}

.ModalOpen {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.ModalClosed {
  display: none; /* if this is active, the animation doesn't work */
  opacity: 0;
  transform: translateY(-100%);
}
