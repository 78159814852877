.main {
  max-width: 800px;
  margin: auto;
}

.bubble {
  background-color: #f3f0dc;
  max-width: 70%;
  padding: 15px;
  border-radius: 20px;
  text-align: left;
  margin: 20px 0;
}

.bubble span {
  margin: 5px;
}

.bubble span:first-child {
  margin-right: 0;
}
.user_msg {
  text-align: right;
  margin-left: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.hide {
  visibility: hidden;
  display: none;
}

.chatForm {
  text-align: center;
  position: sticky;
  bottom: 0;
  margin-bottom: 15px;
}
.chatInput {
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px;
  font-size: 1.2rem;
  background-color: #d7bfdc;
}

input:focus {
  outline: none;
}
