#root {
  position: relative;
  min-height: 100vh;
}
.App {
  text-align: center;
  padding-bottom: 250px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.headline {
  font-family: "Playfair Display", serif;
  white-space: nowrap;
  font-size: 3vw;
}

.App-header {
  background-color: #f3f0dc;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #56001e;
}

.App-link {
  color: #61dafb;
}

hr.animated {
  width: 100%;
  color: #56001e;
  animation: expand 2s ease-in-out;
  border-top: solid 5px #56001e;
  margin: 0;
  box-sizing: border-box;
}
.quote {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  font-style: italic;
  font-size: 2em;
  font-weight: 200;
  padding: 16vh 0;
  color: #56001e;
}
body {
  background-color: rgb(252, 252, 252);
}

@keyframes expand {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@media only screen and (max-width: 740px) {
  .App-header {
    flex-direction: column;
  }
}

.error {
  color: red;
  font-weight: 900;
}
